var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AError", { attrs: { api: this.api } }),
      _c(
        "v-btn",
        {
          staticClass: "my-3",
          attrs: { color: "primary" },
          on: { click: _vm.closeDetail },
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "secondary" } },
        [
          _c("div", { staticClass: "text-h5 pa-5 white--text" }, [
            _vm._v(" Renewal update "),
          ]),
          this.company != null
            ? _c("p", { staticClass: "px-5 white--text" }, [
                _vm._v(_vm._s(_vm.company.name)),
              ])
            : _vm._e(),
          _c(
            "v-list",
            { attrs: { color: "" } },
            [
              _vm.api.isLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "card-avatar" },
                  })
                : _vm._e(),
              _vm._l(_vm.renewal, function (item, index) {
                return _c(
                  "v-list-item",
                  { key: index },
                  [
                    _c(
                      "v-container",
                      [
                        _c("v-card", [
                          _c("div", { staticClass: "pa-4" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start text-h6" },
                              [_vm._v(" " + _vm._s(item.status) + " ")]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex justify-start mb-3" },
                              [
                                item.renewal_status == "Confirm"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "green" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.renewal_status) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.renewal_status == "Confirm - Pending HRDC"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "green" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.renewal_status) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.renewal_status == "Rejected"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "white--text",
                                        attrs: { color: "red" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.renewal_status) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.renewal_status == "Unreachable"
                                  ? _c("v-chip", { attrs: { color: "grey" } }, [
                                      _vm._v(
                                        " " + _vm._s(item.renewal_status) + " "
                                      ),
                                    ])
                                  : _vm._e(),
                                item.renewal_status == "Pending"
                                  ? _c(
                                      "v-chip",
                                      {
                                        staticClass: "black--text",
                                        attrs: { color: "yellow" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.renewal_status) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "d-flex justify-start" }, [
                              _vm._v(" Follow up date : "),
                              _c(
                                "p",
                                { staticClass: "font-weight-bold mx-2" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.convertTimeZone(item.created_at)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                            item.renewal_status == "Rejected" &&
                            item.refund == "1"
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start " },
                                  [
                                    _vm._v(
                                      " Refund requested date : " +
                                        _vm._s(" ") +
                                        " "
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "font-weight-bold mx-2" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.created_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            item.renewal_status == "Confirm" &&
                            item.confirm_at != null
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2 ",
                                    },
                                    [
                                      _vm._v(" Client payment date : "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "font-weight-bold mx-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.convertTimeZone(
                                                  item.confirm_at
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            item.renewal_status == "Pending" &&
                            item.next_followup_at != null
                              ? _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-start " },
                                    [
                                      _vm._v(" Next Follow up date :"),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "font-weight-bold mx-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.convertTimeZone(
                                                  item.next_followup_at
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            item.renewal_status == "Rejected" &&
                            item.refund != null
                              ? _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(" Refund : " + _vm._s(" ") + " "),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "font-weight-bold mx-2",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.refund == "1"
                                                  ? "Yes"
                                                  : "No"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("hr"),
                            item.fname != null
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex justify-start my-2" },
                                  [
                                    _vm._v(" Report by : "),
                                    _c(
                                      "div",
                                      { staticClass: "font-weight-bold" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.fname) +
                                            " " +
                                            _vm._s(item.lname) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "mt-2" },
                              [
                                item.remark != null
                                  ? _c("mavon-editor", {
                                      staticStyle: {
                                        "z-index": "0",
                                        "min-height": "100px",
                                        height: "auto",
                                        width: "100%",
                                      },
                                      attrs: {
                                        height: "auto",
                                        width: "auto",
                                        defaultOpen: "preview",
                                        toolbarsFlag: false,
                                        subfield: false,
                                        language: "en",
                                        editable: false,
                                      },
                                      model: {
                                        value: item.remark,
                                        callback: function ($$v) {
                                          _vm.$set(item, "remark", $$v)
                                        },
                                        expression: "item.remark",
                                      },
                                    })
                                  : _c("mavon-editor", {
                                      staticStyle: {
                                        "z-index": "0",
                                        "min-height": "100px",
                                        height: "auto",
                                        width: "100%",
                                      },
                                      attrs: {
                                        height: "auto",
                                        width: "auto",
                                        defaultOpen: "preview",
                                        toolbarsFlag: false,
                                        subfield: false,
                                        language: "en",
                                        editable: false,
                                      },
                                      model: {
                                        value: _vm.noDescription,
                                        callback: function ($$v) {
                                          _vm.noDescription = $$v
                                        },
                                        expression: "noDescription",
                                      },
                                    }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }